.profile-dropdown {
  position: relative;
  display: inline-block;
}

.profile-icon {
  font-size: 24px;
  cursor: pointer;
  color: goldenrod;
  padding: 10px;
}

.dropdown-menu {
  position: absolute;
  top: 50px; /* Adjust based on where you want the dropdown to appear */
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000; /* Ensures it appears above other content */
  white-space: nowrap; /* Prevent the text from wrapping */
}

.dropdown-item {
  display: block;
  padding: 10px 20px;
  text-decoration: none;
  color: black;
}

.dropdown-item:hover {
  background-color: goldenrod;
  color: white;
}

.dropdown-item-logout {
  background-color: goldenrod;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdown-item-logout:hover {
  background-color: darkgoldenrod;
}
