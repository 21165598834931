/* Proposals.css */

.proposal {
  align-items: center;
}

.proposals-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 20px;
}

.prop {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.prop-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  
}

.proposal strong {
  font-weight: bold;
}

.proposal .prop {
  margin-bottom: 1em;
}

.proposal .prop-buttons {
  margin-top: 1em;
}


.checkbox {
  display: flex;
}

.header-buttons {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: 95%;
  margin-bottom: 20px;
}

.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: goldenrod;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #d4af37;
}

.create-proposal-form,
.proposal {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  border: 2px solid gold;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 16px;
  padding: 16px;
  width: 90%;
  margin-right: 58px;
}

.create-proposal-form input,
.create-proposal-form textarea,
.create-proposal-form button {
  margin-bottom: 15px;
  max-width: 600px;
  width: 70%;
}



.create-proposal-form input,
.create-proposal-form textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.create-proposal-form textarea {
  resize: vertical;
}

.proposal-image {
  width: 10%;
  height: auto;
  margin: 10px 0;
  border-radius: 10px;
}

.proposal p {
  margin: 5px 0;
  padding: 10px;
}

.wallet-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--background-color-light);
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content .dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
}

.dropdown-content .dropdown-item:hover {
  background-color: var(--hover-color);
  color: white;
}

.wallet-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}


/* Responsive Design */
@media (max-width: 768px) {
  .header-buttons {
    flex-direction: column;
    align-items: center;
    width: 85%;
  }

  .proposal {
    width: 70%;
  }

  .proposal p {
    padding: 1px;
  }

  .prop, .prop-buttons {
    display: flex;
    flex-direction: column;
}


.create-proposal-form {
  width: 80%;
}

  .button {
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }

  .proposal-image {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .proposals-container {
    padding: 10px;
    margin: 16px;
  }

  .button {
    font-size: 14px;
    padding: 8px 15px;
  }

  .create-proposal-form input,
  .create-proposal-form textarea {
    padding: 8px;
  }

    .proposal p {
    padding: 1px;
  }


    .prop, .prop-buttons {
    display: flex;
    flex-direction: column;
}
  
}

/* New styles for search filter */
.search-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
  padding: 0 20px;
  width: 70%;
}

.search-filter-container input {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.filter-dropdown {
  position: relative;
  display: inline-block;
  align-items: center;
  cursor: pointer;
}

.filter-dropdown .fa-filter {
  font-size: 1.5rem;
  color: goldenrod;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  border: 1px solid goldenrod;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  background-color: var(--background-color-light);
  color: var(--text-color-light);
}

body.dark-mode .dropdown-menu {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}

.dropdown-menu div {
  padding: 10px;
  cursor: pointer;
}
