:root {
  --button-background-light: goldenrod;
  --button-background-dark: darkgoldenrod;
  --button-text-color-dark: #ffffff;
  --border-color-light: gold;
  --border-color-dark: gold;
  --text-color-light: #000000;
  --text-color-dark: #ffffff;
  --background-color-light: transparent;
  --background-color-dark: #131111;
}

.nft-card {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 2px solid var(--border-color-light, gold);
  border-radius: 25px;
  padding: 16px;
  margin: 16px;
  background-color: var(--background-color-light, transparent); /* Ensure transparency */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 950px;
  width: 95%;
  box-sizing: border-box;
  color: var(--text-color-light, #000000); /* Ensure text color is set for light mode */
}

body.dark-mode .nft-card {
  background-color: var(--background-color-dark, #131111);
  color: var(--text-color-dark, #ffffff); /* Ensure text color is set for dark mode */
}

body.dark-mode .nft-card h2,
body.dark-mode .nft-card p {
  color: var(--text-color-dark, #ffffff); /* Ensure h2 and p colors are set for dark mode */
}

.nft-card img {
  max-width: 60%;
  height: auto;
  border-radius: 5px;
  margin-right: 16px;
}

.nft-card-content {
  font-family: "Orbitron", sans-serif;
  flex: 1;
  display: flex;
  flex-direction: column;
  color: var(--text-color-light, #000000); /* Ensure text color is set for light mode */
  align-items: start;
}

.nft-card h2 {
  margin-top: 0;
  font-size: 1.2em;
  color: var(--text-color-light, #000000); /* Ensure h2 color is set for light mode */
}

.nft-card p {
  margin: 8px 0;
  color: var(--text-color-light, #000000); /* Ensure p color is set for light mode */
}

.nft-card button {
  margin-top: 8px;
  padding: 10px 20px;
  background-color: var(--button-background-light, goldenrod);
  border: 2px solid var(--border-color-light, gold);
  border-radius: 5px;
  color: var(--button-text-color-light, #000000);
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.nft-card button:hover {
  background-color: #ffd700;
}

.nft-card-details {
  width: 100%;
  text-align: left;
  margin-top: 16px;
  display: none; /* Hide details by default */
  color: var(--text-color-light, #000000); /* Ensure details text color is set for light mode */
}

.nft-card-details.expanded {
  display: block; /* Show details when expanded */
}

.nft-card a {
  color: blue;
  text-decoration: none;
}

.nft-card a:hover {
  text-decoration: underline;
}

@media (prefers-color-scheme: dark) {
  .nft-card {
    border-color: var(--border-color-dark, gold);/* Ensure text color is set for dark mode */
  }

  

  .nft-card button {
    background-color: var(--button-background-dark, darkgoldenrod);
    color: var(--button-text-color-dark, #ffffff);
    border-color: var(--border-color-dark, gold);
  }

  .nft-card button:hover {
    background-color: #e6c200;
  }

  .nft-card-details {
    color: var(--text-color-dark, #ffffff); /* Ensure details text color is set for dark mode */
  }
}

@media (max-width: 600px) {
  .card {
    max-width: 100%;
    width: 100%;
  }

  .nft-card {
    flex-direction: column;
    align-items: center;
    max-width: 90%;
  }

  .nft-card img {
    max-width: 80%;
    margin-right: 0;
    margin-bottom: 16px;
  }

  .nft-card-content {
    align-items: start;
  }

  .nft-card h2 {
    font-size: 1em;
  }

  .nft-card p {
    font-size: 0.9em;
  }

  .nft-card button {
    font-size: 14px;
    padding: 8px 16px;
  }
}
