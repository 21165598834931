.bot-invite-container {
    margin: 20px;
    text-align: center;
    border: 2px solid gold;
    display: flex;
    flex-direction: column-reverse;
    border-radius: 25px;
    align-items: center;
}

.invite-text {
    font-size: 1.2em;
    margin-bottom: 20px;
    max-width: 200px;
}

.bot-invites {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.bot-invite {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
}

.bot-image {
    width: 250px;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s;
    padding: 16px;
    border-radius: 25px;
}

.bot-image:hover {
    transform: scale(1.1);
}

.bot-invite p {
    margin-top: 10px;
    font-size: 1em;
    color: #333;
}

@media (max-width: 1200px) {
    .bot-invite-container {
        border-radius: 11px;
        display: flex;
        margin: 20px;
        text-align: center;
    }

    .invite-text {
        font-size: 1.2em;
        margin-bottom: 20px;
        max-width: 650px;
    }

    .bot-invites {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }
}

@media (max-width: 600px) {
    .bot-invite-container {
        border: none;
        display: flex;
        margin: 20px;
        text-align: center;
    }

    .invite-text {
        font-size: 1.2em;
        margin-bottom: 20px;
        max-width: 650px;
    }

    .bot-invites {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        justify-content: center;
    }
}
