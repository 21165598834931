:root {
  --text-color-light: #000000;
  --text-color-dark: #ffffff;
  --background-color-light: #ffffff;
  --background-color-dark: #131111;
  --nav-link-color-light: black;
  --nav-link-color-dark: white;
  --footer-text-color-light: goldenrod;
  --footer-text-color-dark: goldenrod;
  --hover-color: goldenrod;
}
body {
  font-family: "Orbitron", sans-serif;
  margin: 0;
  padding: 0;
  color: var(--text-color-light);
  background-color: var(--background-color-light);
}
body.dark-mode {
  background-color: var(--background-color-dark);
  color: var(--text-color-dark);
}
h1 {
  color: var(--text-color-light);
  font-size: 1.5em;
  padding: 5px;
  text-decoration: underline solid goldenrod;
}
body.dark-mode h1 {
  color: var(--text-color-dark);
}
.dropdown-menu {
  background-color: rgba(24, 23, 23, 0.445);
  width: fit-content;
  border-radius: 10px;
}
body.dark-mode .dropdown-menu {
  border-color: goldenrod;
}
.sides {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.dropdown-item {
  color: var(--text-color-light);
}
body.dark-mode .dropdown-item {
  color: var(--text-color-dark);
}
.dropdown-item:hover {
  color: #f0f0f0;
}
body.dark-mode .dropdown-item:hover {
  color: goldenrod;
}
.search {
  color: goldenrod;
}
.snapshot-section {
  border: 2px solid gold;
  border-radius: 25px;
  margin-top: 20px;
  padding: 20px;
  align-items: center;
}
.snapshot-results {
  margin-top: 10px;
  padding: 10px;
  border-radius: 25px;
}
.snapshot-results ul {
  list-style-type: none;
  padding: 0;
}
.snapshot-results ul li {
  margin: 5px 0;
}

div {
  display: flex;
  padding: 2px;
  justify-content: center;
  flex-direction: column;
}

  
.filter-container {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  padding: 10px;
}
.header {
  color: var(--text-color-light);
  padding: 10px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
body.dark-mode .header {
  color: var(--text-color-dark);
}
.search-filter-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
  width: 70%;
  padding: 0 20px;
}
.ads {
  align-items: center;
}
.search-filter-container input {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.filter-dropdown {
  position: relative;
  display: inline-block;
  align-items: center;
  cursor: pointer;
}
.filter-dropdown .fa-filter {
  font-size: 1.5rem;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  border: 1px solid goldenrod;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}
.dropdown-menu-1 {
  position: absolute;
  top: 100%;
  border: 1px solid goldenrod;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--background-color-light);
  z-index: 1000;
}
.dropdown-menu div {
  padding: 10px;
  cursor: pointer;
}
.title {
  font-size: 1em;
  padding: 5px;
}
.nav {
  display: flex;
  gap: 20px;
}
.nav-link,
.nav-button {
  color: var(--nav-link-color-light);
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-family: "Orbitron", sans-serif;
  font-size: 1.2rem;
}
body.dark-mode .nav-link,
body.dark-mode .nav-button {
  color: var(--nav-link-color-dark);
}
.nav-link:hover,
.nav-button:hover {
  color: var(--hover-color);
}
body.dark-mode .nav-link:hover,
body.dark-mode .nav-button:hover {
  color: var(--hover-color);
}
.footer {
  color: var(--footer-text-color-light);
  padding: 20px 0;
  text-align: center;
  width: 100%;
  bottom: 0;
}

body.dark-mode .tabs-container button {
  background: var(--button-bg-dark); /* Background for dark mode */
  color: var(--button-text-color-dark); /* Text color for dark mode */
}

body.dark-mode .footer {
  color: var(--footer-text-color-dark);
}
.social-links {
  list-style: none;
  padding: 0;
  margin: 10px 0 0 0;
  display: flex;
  gap: 15px;
}
.social-links li {
  display: inline;
}
.social-links a {
  color: inherit;
  text-decoration: none;
  transition: color 0.3s ease;
}
.social-links a:hover {
  color: var(--footer-text-color-dark);
}
.main-content {
  display: flex;
  flex-direction: row;
  align-items: start;
}
.snapshot-results ul {
  list-style-type: none;
  padding: 0;
}
.snapshot-results ul li {
  margin: 5px 0;
}
@media (max-width: 600px) {
  .card {
    max-width: 100%;
    width: 100%;
  }
  .snapshot-section {
    width: 80%;
  }
  .main-content {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .sides {
    flex-direction: column;
  }
  .tabs-container {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 1200px) {
  .main-content {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  .sides {
    flex-direction: column;
  }
}
/* Additional styles for the tab links */
.tabs-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px 0;
  text-transform: uppercase;
}
.tabs-container button {
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  font-family: "Orbitron", sans-serif;
  font-size: 1rem;
  color: var(--text-color-light);
  transition: color 0.3s ease;
}
.tabs-container button:hover,
.tabs-container button.active {
  color: var(--hover-color);
}

@media (min-width: 1200px) {
  .tabs-container {
    gap: 200px; /* Gap for desktop */
  }
}
