/* PostForm.css */
.post-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    /* background-color: #f0f0f0; */
  }
  
  .post-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 2px solid gold;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 600px;
    max-width: 100%;
  }
  
  .post-form input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  .droptypes {
    margin-bottom: 15px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
  }

  .postDetails {
    margin-bottom: 15px;
      padding: 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
  }
  
  .post-form button {
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: goldenrod;
    color: white;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .post-form button:hover {
    background-color: #d4af37;
  }
  
@media (max-width: 600px) {
  .post-form {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 2px solid gold;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px;
    max-width: 100%;
  }
    
}
