.mint-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.section-title {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
}

.mint-section {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 40px;
}

.mint-card {
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  max-width: 500px;
}

.mint-card-image {
  width: 100%;
  height: auto;
}

.mint-card-info {
  padding: 20px;
  text-align: center;
  color: black; /* Ensure card info text stays black */
}

.mint-card-info h3 {
  font-size: 20px;
  margin-bottom: 10px;
  color: black; /* Ensure card title stays black */
}

.mint-card-info p {
  font-size: 16px;
  margin-bottom: 20px;
  color: black; /* Ensure paragraph text stays black */
}

.mint-button {
  background-color: #f0b90b;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.mint-button:hover {
  background-color: #d9a007;
}
