.carousel .slide img {
    width: 100%;
    height: 200px; /* Set a fixed height for the carousel images */
    object-fit: cover; /* Crop the image to fit the container */
    border-radius: 10px;
  }
  
  .carousel .legend {
    background: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    font-size: 1em;
    text-align: center;
  }

.carousel-root {
    outline: none;
    max-width: 1300px;
}
  
  .carousel .control-arrow,
  .carousel .control-next,
  .carousel .control-prev {
    background: rgba(0, 0, 0, 0.5);
  }
  
