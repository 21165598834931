.logout-button {
    background-color: goldenrod;
    color: black;
    border: 2px solid gold;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .logout-button:hover {
    background-color: darkgoldenrod;
    /* color: white; */
  }

.login-form input {
    border: 1px solid #daa520;
    border-radius: 4px;
    margin: 10px 0;
    padding: 10px;
    width: 95%;
}
  
