.profile-container {
  padding: 20px;
}

.profile-buttons {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  margin-bottom: 20px;
  justify-content: space-between;
}

.connect-wallet-button,
button {
  background-color: goldenrod;
  border: none;
  border-radius: 8px;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  margin: 4px 2px;
}

.connect-wallet-button:hover,
button:hover {
  background-color: darkgoldenrod;
}

.nft-drops,
.wallet-holdings {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
}

.wallet-holding {
  border: 1px solid goldenrod;
  border-radius: 8px;
  padding: 16px;
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.wallet-holding img,
.inscription-card img {
  max-width: 100px;
  border-radius: 4px;
}

.wallet-holding p,
.inscription-card p {
  margin: 4px 0;
}


.snapshot-results {
  margin-top: 10px;
  padding: 10px;
  border-radius: 25px;
}

.snapshot-results ul {
  list-style-type: none;
  padding: 0;
}

.snapshot-results ul li {
  margin: 5px 0;
}

/* Additional styles for the Profile component */
.profile-container {
  padding: 20px;
}

.profile-buttons {
  display: flex;
  align-items: center;
}

.wallet-dropdown {
  position: relative;
  display: inline-block;
}

.wallet-dropdown button {
  border: none;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: var(--background-color-light);
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-width: 160px;
  z-index: 1;
  border-radius: 8px;
}

.wallet-dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-item {
  color: var(--text-color-light);
  padding: 12px 16px;
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.dropdown-item img {
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.dropdown-item:hover {
  background-color: var(--hover-color);
  color: var(--text-color-dark);
}

.wallet-icon {
  width: 20px;
  height: 20px;
}

.wallet-view {
  text-align: center;
}

.wallet-holdings {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.inscription-card {
  border: 2px solid goldenrod;
  border-radius: 8px;
  padding: 16px;
  width: 200px;
  text-align: center;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.inscription-id {
  font-size: 12px;
  color: #333;
  overflow-wrap: anywhere;
}


.inscription-id {
  word-wrap: break-word;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  font-size: 12px;
}




@media (max-width: 600px) {
  .profile-buttons {
    flex-direction: row;
    gap: 5px;
  }

  .nft-drops,
  .wallet-holdings {
    flex-direction: column;
    gap: 10px;
  }

  .wallet-holdings,
  .inscription-card,
  .snapshot-section {
    width: 95%;
  }
}
