.header {
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
}

.title {
  margin: 0;
  font-family: "Orbitron", sans-serif;
  font-size: 1.5em;
}

.nav {
  display: flex;
  align-items: center;
  font-family: "Orbitron", sans-serif;
}

.nav-links {
  display: flex;
  align-items: center;
  flex-direction: row; /* Ensure links are in a row by default */
}

.nav-link {
  text-decoration: none;
  margin: 0 10px;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #0f0e0e;
}

.nav-button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  margin: 0 10px;
  font-size: 1em;
  transition: color 0.3s ease;
}

.nav-button:hover {
  color: #ddd;
}

/* Mobile Menu Icon */
.mobile-menu-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

/* Profile Icon */
.profile-icon {
  display: block; /* Always visible when logged in */
  font-size: 24px;
  cursor: pointer;
  margin-left: 10px;
}

/* Responsive Design for Smaller Screens */
@media (max-width: 600px) {
  .nav-links {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px; /* Adjust based on header height */
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }

  .nav-links.open {
    display: flex;
  }

  .mobile-menu-icon {
    display: block;
  }

  .nav-link {
    margin: 10px 0;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
  }

  .header {
    flex-direction: row;
  }

  .title {
    font-size: 1.2em;
    margin-bottom: 10px;
  }

  .profile-icon {
    display: block; /* Ensures the profile icon is visible on mobile as well */
    margin-left: auto;
  }
}
