.trending-container {
  padding: 20px;
  text-align: center;
  max-width: 1000px;
  width: 80%;
}

.trending-header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.trending-controls-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 10px;
  flex-wrap: nowrap;
}

.positive-change {
  color: green;
}

.negative-change {
  color: red;
}

.buy-button {
  background-color: #4CAF50; /* Green */
  border: none;
  color: white;
  padding: 8px 12px; /* Smaller padding for a more compact button */
  text-align: center;
  text-decoration: none;
  font-size: 14px; /* Slightly smaller font size */
  margin: 10px auto; /* Center the button */
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
  width: auto; /* Make sure the button is not too wide */
  display: inline-block; /* Ensures proper inline-block behavior */
}

.buy-button:hover {
  background-color: #45a049;
}




.trending-search-input {
  padding: 10px;
  font-size: 16px;
  border: solid 2px gold;
  border-radius: 10px;
  box-sizing: border-box;
  flex-grow: 1;
}

.trending-filter-dropdown {
  position: relative;
  display: inline-block;
}

.trending-filter-dropdown .dropdown-menu {
  right: 0;
  left: auto;
  position: absolute;
  top: 100%; /* Make it appear right under the filter icon */
  z-index: 1000;
  min-width: 150px; /* Set a minimum width to ensure it's not too small */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}


.trending-dune-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Additional styles that may be necessary for proper layout */


/* Existing styles for other components */
.password-lock {
  text-align: center;
  margin-top: 50px;
}
.password-form {
  margin-top: 20px;
}
.error-message {
  color: red;
  margin-top: 10px;
}


.trending-payment-popup {
  color: black;
  /* Add any additional styling you need */
}

.trending-sort-container,
.trending-search-container {
  flex: 1;
}

.wonkyi {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 25px;
}

.info-note {
  display: flex;
  flex-direction: row-reverse;
  gap: 25px;
  align-items: center;
  margin-bottom: 15px;
  font-size: 12px;
  color: #555;
  cursor: pointer;
}

.info-icon {
  font-size: 16px;
  margin-right: 5px;
  cursor: pointer;
}

.info-text {
  margin: 0;
  line-height: 1.4;
  display: none;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  max-height: 0;
  opacity: 0;
  overflow: hidden;
}

.info-text.visible {
  display: block;
  max-height: 300px; /* Adjust as needed */
  opacity: 1;
}


.trending-ttitle {
  cursor: pointer;
}

.trending-balance-container,
.trending-sort-container {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap;
}

.trending-balance-container input {
  padding: 10px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.trending-balance-container button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  max-width: 150px;
  box-sizing: border-box;
}

.trending-dune-list,
.trending-wallet-dunes-list,
.trending-drc20-list,
.trending-nft-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.trending-wallet-dune-card,
.trending-dune-card,
.trending-nftCard,
.trending-drc20-card {
  border: 2px solid gold;
  padding: 20px;
  border-radius: 11px;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

   .trending-nftCard,
   .trending-drc20-card {
    display: flex;
    flex-direction: row;
   }

 

.trending-drc20-card img,
.trending-nftCard img {
  max-width: 120px;
  border-radius: 20px;
  max-height: 120px;
}

.trending-dune-card a {
  text-decoration: none;
  color: inherit;
  word-wrap: break-word;
}

.dune-form {
  color: black; /* Ensures the text color is always dark */
}

.dune-form label {
  color: black; /* Ensures label text is always dark */
}

.dune-form input,
.dune-form select {
  color: black; /* Ensures input text is always dark */
  background-color: white; /* Optional: Keeps inputs' background white for better contrast */
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}

.dune-form button[type="submit"] {
  background-color: goldenrod; /* Keep the button styling consistent */
  color: white; /* Ensures the button text color is always white */
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.dune-form button[type="submit"]:hover {
  background-color: gold;
}

.trending-dune-card h2,
.trending-drc20-card h2 {
  font-size: 18px;
  color: goldenrod;
}

.trending-drci {
  flex-direction: row;
  align-items: center;
}

.trending-error {
  color: red;
}

.trending-form-container {
  margin: 0 auto;
  color: black;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.trending-form-container label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.trending-form-container input[type="text"],
.trending-form-container input[type="number"],
.trending-form-container select {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ddd;
  box-sizing: border-box;
}

.trending-form-container input[type="checkbox"] {
  margin-right: 10px;
}

.trending-form-container button[type="submit"] {
  width: 100%;
  padding: 10px;
  background-color: goldenrod;
  border: none;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.trending-form-container button[type="submit"]:hover {
  background-color: gold;
}

.trending-payment-popup {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* Mobile-specific styles */
@media (max-width: 768px) {
  .trending-dune-list,
  .trending-wallet-dunes-list,
  .trending-nft-list,
  .trending-drc20-list {
    flex-direction: column;
    align-items: center;
  }

  .trending-drci {
  flex-direction: column;
  align-items: center;
}
  
  trending-container {
    width: 400px;
    padding: 20px;
    text-align: center;
  }
  .trending-dune-card,
  .trending-wallet-dune-card,
  .trending-nftCard,
  .trending-drc20-card {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    text-align: left;
  }
  .trending-dune-card h2,
  .trending-drc20-card h2 {
    font-size: 16px;
    word-wrap: break-word;
  }
  .trending-nftCard img,
  .trending-drc20-card img {
    margin: 0 auto 10px;
    max-width: 120px;
    height: auto;
  }
}
